import {
  Button,
  Container,
  Divider,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import * as React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import headerback from "../assets/image/headerbackground.png"

const companyCategory = [
    {
      value: "Consolitor",
      label: "Consolitor",
    },
    {
      value: "Corporate",
      label: "Corporate",
    },
    {
      value: "Destination Management Company",
      label: "Destination Management Company",
    },
    {
      value: "Meta Search Engine",
      label: "Meta Search Engine",
    },
    {
        value: "Online Travel Agency",
        label: "Online Travel Agency",
      },
      {
        value: "Tour Operator",
        label: "Tour Operator",
      },
      {
        value: "Travel Management Company",
        label: "Travel Management Company",
      },
      {
        value: "Others",
        label: "Others",
      },
  ];

const monthlyTickets =[
    {
        value:"More Than, 1,00,000",
        label:"More Than, 1,00,000"
    },
    {
        value:"30,000 to 1,00,000",
        label:"30,000 to 1,00,000"
    },
    {
        value:"5,000 to 30,000",
        label:"5,000 to 30,000"
    },
    {
        value:"below 5,000",
        label:"below 5,000"
    }
]

function StartHere() {

  const [state, setState] = React.useState({
    APAC: false,
    SoutheastAsia: false,
    EMEA: false,
    Americas: false,
    Agree: false,
    getInTouch: false,
  });

  const handleChangeCheck = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { Agree, getInTouch } = state;
  return (
    <div>
        <div
      style={{
        backgroundImage: "url(" + headerback + ")",
        backgroundSize: "cover",
        height: "150px",
      }}
    >
        <Container>
        <Typography
          variant="h4"
          style={{ paddingTop: "50px", marginBottom: "10px", color:"white" }}
        >
          Get Started Here
        </Typography>
        </Container>
    </div>
      <Container>
        <p style={{ marginTop: "10px", marginBottom: "10px" }}>
          Our global air fares and ancillary hub is your one-stop shop for all
          your air travel business needs.
        </p>
        <p>Join the gfa-hub team today!</p>
        <Divider style={{ width: "70%", marginBottom: "10px" }}></Divider>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="First Name"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Last Name"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Job Title"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Business Email"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Contact Number"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Company Name"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-select-currency"
              select
              fullWidth
              label="Company Category"
              //onChange={handleChange}
              variant="filled"
            >
              {companyCategory.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-select-currency"
              select
              fullWidth
              label="Minimum number of tickets every Month"
              //onChange={handleChange}
              variant="filled"
            >
              {monthlyTickets.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Country"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-basic"
              label="Monthly Ticket Sale in USD"
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              We're committed to your privacy. We uses the information you
              provide to us to contact you about our relevant content, products,
              and services. You may unsubscribe from these communications at any
              time. For more information, check out our Privacy Policy.
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getInTouch}
                    onChange={handleChangeCheck}
                    name="getInTouch"
                    color="primary"
                  />
                }
                label="I'd like to stay in touch through digital communication from you."
              />
            </div>
            <div>
              In order to provide you the content requested, we need to store
              and process your personal data. If you consent to us storing your
              personal data for this purpose, please tick the checkbox below.
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Agree}
                    onChange={handleChangeCheck}
                    name="Agree"
                    color="primary"
                  />
                }
                label="I agree to allow you to store and process my personal data"
              />
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<ArrowForwardIcon />}
        >
          Submit To Start
        </Button>
      </Container>
    </div>
  );
}

export default StartHere;
