import React from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  withStyles,
  Grid,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Theme from "../Theme";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import Flip from "react-reveal/Flip";

import feature1 from "../assets/image/feature1.png";
import feature2 from "../assets/image/feature2.png";
import feature3 from "../assets/image/feature3.png";
import feature4 from "../assets/image/feature4.png";
import feature5 from "../assets/image/feature5.png";
import feature6 from "../assets/image/feature6.png";
import feature7 from "../assets/image/feature7.png";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Carousel from "react-material-ui-carousel";
import firstCarosal from "../assets/image/carosal1.png";
import secondCarosal from "../assets/image/carosal2.png";

var items = [
  {
    name: "Get Flight and Ancillary Hub (GFA Hub)",
    description:
      "Next-generation airline retailing, and early-adopters are well on their way to reaping the benefits.",
    image: firstCarosal,
  },
  {
    name: "Developer Friendly API",
    description:
      "Developers will be using our API to build applications, so providing them with as much information as we can will aid them in creating high quality experiences on top of their platform",
    image: secondCarosal,
  },
];

function Item(props) {
  return (
    <div
      style={{
        backgroundImage: "url(" + props.item.image + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "400px",
      }}
    >
      <Container>
        <Typography
          variant="h3"
          style={{ color: "white", paddingTop: "100px", width: "600px" }}
        >
          {props.item.name}
        </Typography>
        <Typography style={{ color: "#ffffcc", width: "600px" }}>
          {props.item.description}
        </Typography>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //marginTop: "40px",
    marginBottom: "-200px",
    overflow: "hidden",
    position: "relative",
  },
  mainheading: {
    marginTop: "100px",
  },
  headingtext: {
    fontWeight: 700,
  },
  headingSecondary: {
    color: "secondary",
  },
  mainImage: {
    marginTop: "25px",
    marginLeft: "100px",
  },
  heading: {
    marginTop: "50px",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: 500,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 100,
  },
  NumberCount: {
    fontWeight: 700,
  },
  //Features css
  featuresroot: {
    flexGrow: 1,
    //marginTop: "-110px",
    backgroundColor: "#efefef",
  },
  content: {
    //marginTop: "140px",
    paddingTop: 50,
    //color: "white",
    fontWeight: 700,
  },
  contentHead: {
    fontWeight: 700,
  },
  contentText: {
    //color: "#d4d4d4",
    marginTop: 10,
  },

  //Products
  productheading: {
    marginTop: "80px",
    textAlign: "center",
    fontWeight: 500,
  },
  productcontent: {
    marginTop: "40px",
  },
  subHeading: {
    marginTop: "10px",
    textAlign: "center",
    fontWeight: 500,
  },
  contentTopHead: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    paddingTop: 20,
  },
  productImage: {
    width: 600,
  },
  productHeading: {
    marginTop: 80,
  },

  //clients
  clientcontent: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: 40,
    color: "white",
  },
  clientheading: {
    paddingTop: "60px",
    textAlign: "center",
    fontWeight: 500,
    color: "white",
  },
  clientsubHeading: {
    //marginTop: "10px",
    marginBottom: "-50px",
    textAlign: "center",
    fontWeight: 500,
    color: "white",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    borderColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
    },
  },
}))(Button);

function Home() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Carousel indicators={false} animation="fade" timeout={500}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </div>
      {/* Features */}
      <div className={classes.featuresroot}>
        <Container>
          <div className={classes.content}>
            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <EmojiObjectsOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        Developer friendly
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        Up to date API documentation, clear guidelines for test,
                        sample code and much more.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <SettingsOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        Get started quickly
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        Developer portal provides you example request and
                        responses payload so that you can start coding
                        immediately.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <FeaturedPlayListOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        Your data secured
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        Industry standard OATH 2.0 ensures that you are
                        authenticated and allowed to access your data.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <TrendingUpOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        High Data Volume
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        Sending large volumes of data and receiving information
                        back in real-time directly from and to your system has
                        never been easier.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <QuestionAnswerOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        Powerful Capability to Book and Support
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        24/7/365 global support through in-house, expert travel
                        agents. Powerful administration and communication
                        capabilities for international duty-of-care.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
              <Grid item xs={4}>
                <Flip bottom>
                  <Box display="flex">
                    <MonetizationOnOutlinedIcon
                      style={{
                        fontSize: 70,
                        color: Theme.palette.primary.main,
                        marginRight: 10,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        color="white"
                        className={classes.contentHead}
                      >
                        Revenue Growth
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contentText}
                      >
                        Our API speeds up your sales process, enabling your
                        platform to drive growth quicker with less risk.
                      </Typography>
                    </Box>
                  </Box>
                </Flip>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      {/* Products */}
      <Container>
        <Typography variant="h5" className={classes.productheading}>
          GFA Hub{" "}
          <Box component="span" color="secondary.main">
            is Retailing Solutions help customers differentiate their products
            with compelling rich content and help our partners create
            informative, attractive shopping displays.
          </Box>
        </Typography>
        <div className={classes.productcontent}>
          <Grid container spacing={5}>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Business travel management made easy
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                Enjoy full control and visibility. Track expenses and reports
                from user-friendly dashboards. Get actionable insights. All in
                one place.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature1}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature2}
              />
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Post Ticketing Solution
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                GFA-HUB supports a Flight Post-Ticketing API that displays
                post-booking information, such as ticket information, or allows
                you to re-issue, cancel, or void a ticket.
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Innovative travel technology solutions
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                Innovative travel technology solution that helps travel
                companies earn more from travel bookings. Travel Agents can
                partner with us by choosing from one of the many unique
                travel-technology solutions we offer.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature3}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature4}
              />
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Payments Made Easy
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                The flexible payment scheduler allows our customers a custom
                payment schedule that spans over up to a week. The customer pays
                in regular intervals, and the schedule is scalable.
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Smart Caching
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                Using our smart caching solution, the flight search result
                response time can be as little as 2 seconds for multiple
                searches at the same time.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature5}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature6}
              />
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Selling Ancillary Airline products
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                Ancillary options present an opportunity to enhance your
                competitive position, in addition to meeting passenger’s varied
                travel needs. With our API solution value-added bundles, you can
                transform your business from seat-selling to bundling of selling
                several products for one price.
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.productHeading}>
              <Typography variant="h6" className={classes.contentHead}>
                Cloud-based Travel Technology Solution
              </Typography>
              <Typography variant="subtitle1" className={classes.contentText}>
                Our developer friendly cloud-based API handle changes
                responsibly. Changes to your API are bound to happen. However,
                existing API users can continue to use the API as-is without the
                new features, and test both of these use cases (preferably with
                your automated test suite). Our API are informative and the more
                efficient. Our developer Friendly APIs are safe with an SSL
                enabled communication between the channels and a Proper
                Authentication Scheme.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img
                alt="company logo"
                className={classes.productImage}
                src={feature7}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      {/* Clients */}
      <div className={classes.clientcontent}>
        <Container style={{ padding: "50px" }}>
          <Grid container justify="center">
            <Grid item>
              <Box flexDirection="column">
                <Typography variant="h4">Ready to get started?</Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: "#ffffcc",
                  }}
                >
                  Sign up or contact us
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{ marginLeft: "100px", marginTop: "20px" }}>
              <ColorButton
                component={Link}
                to="/StartHere"
                variant="outlined"
                endIcon={<ArrowForwardIcon />}
                style={{ width: "200px" }}
              >
                Start Here
              </ColorButton>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Home;
