import { Container,Box, Divider } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <Divider />
            <Container style={{padding:40}}>
                <Box display="flex">
                    <Box>
                    @Copy Rights 2021 - Akbar Offshore Pvt. Ltd.
                    </Box>
                    <Box display="flex" style={{marginLeft:"auto"}}>
                        <Box style={{marginLeft:40}}>
                            <Link to="/">Home</Link>
                        </Box>
                        <Box style={{marginLeft:40}}>
                        <Link to="/ContactUs">Contact Us</Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default Footer
