import React from "react";
import { Container, Typography } from "@material-ui/core";
import headerback from "../assets/image/headerbackground.png";
import Theme from "../Theme";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import PhoneEnabledOutlinedIcon from "@material-ui/icons/PhoneEnabledOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";

function ContactUs() {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + headerback + ")",
          backgroundSize: "cover",
          height: "150px",
        }}
      >
        <Container>
          <Typography
            variant="h4"
            style={{ paddingTop: "50px", marginBottom: "10px", color: "white" }}
          >
            Contact Us
          </Typography>
        </Container>
      </div>
      <Container>
        <div style={{ paddingTop: "100px", marginBottom: "10px" }}>
          <h1>Need Any Help For Business & Consulting</h1>
          <p style={{ paddingTop: "50px", marginBottom: "10px" }}>
            YOU'VE GOT QUESTIONS, WE'VE GOT ANSWERS.
          </p>
        </div>
        <div style={{ width: "600px", paddingTop: "20px" }}>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
            <PinDropOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></PinDropOutlinedIcon>
            178, 1st Floor, Kavarana Mansion, Dr Baba Saheb Ambedkar Rd, Dadar
            East, Mumbai, Maharashtra 400014.
          </p>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
          <PhoneEnabledOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></PhoneEnabledOutlinedIcon>
            +91 (022) 40003434
          </p>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
          <EmailOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></EmailOutlinedIcon>
            info@akbaroffshore.com
          </p>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
          <EmailOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></EmailOutlinedIcon>
            For Sales : sales@getfares.com
          </p>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
          <EmailOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></EmailOutlinedIcon>
            For HR & Admin : hr@akbaroffshore.com
          </p>
          <p style={{ fontSize: "1.2em", paddingBottom: "10px" }}>
          <EmailOutlinedIcon
              style={{
                fontSize: 30,
                color: Theme.palette.primary.main,
                marginRight: 10,
              }}
            ></EmailOutlinedIcon>
            For Marketing : digital.mktg@akbaroffshore.com
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ContactUs;
