import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Menu from "@material-ui/core/Menu";
import { MenuItem, Divider, Paper, Typography } from "@material-ui/core";
import logo from "../assets/image/logo.png";
import developerPortal from "../assets/image/DeveloperPortal.png";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  logo: {
    height: "4em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4em",
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    marginRight: "10px",
  },
  tabContainer: {
    //marginLeft: "auto",
    //marginRight: "25px",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "10px",
  },
  scaddDrawer: {
    width: 350,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  scaddDrawerPaper: {
    width: 350,
    paddingTop: 64,
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#e4e4e4",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  //cart related Css
  cart: {
    padding: "10px",
  },
  CartType: {
    display: "flex",
    fontSize: "1em",
  },
}));
function Header() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" color="white" className={classes.appbar}>
          <Container>
            <Toolbar disableGutters>
              <Tabs
                //value={value}
                //onChange={handleChange}
                className={classes.tabContainer}
              >
                <Tab
                  className={classes.tab}
                  component={Link}
                  to="/"
                  label="Home"
                ></Tab>
                <Tab
                  className={classes.tab}
                  label="Developer"
                  onClick={handleClick}
                  onMouseOver={handleClick}
                ></Tab>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Paper
                    elevation={0}
                    style={{ width: "500px", marginTop: "45px" }}
                    onMouseOut={handleClose}
                    onMouseOver={handleClick}
                  >
                    <Divider />
                    <Grid container>
                      <Grid item xs={6}>
                        <img
                          alt="company logo"
                          style={{ width: "240px", marginLeft: "10px" }}
                          src={developerPortal}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <React.Fragment>
                          <div
                            style={{ marginTop: "40px", textAlign: "center" }}
                          >
                            GFA-Hub is designed to be an all-in-one set of APIs
                            that lets you do everything you need.
                          </div>
                          <Button
                            component={Link}
                            to={{ pathname: "https://dev.gfa-hub.com/" }}
                            target="_blank"
                            variant="contained"
                            color="primary"
                            size="large"
                            endIcon={<ArrowForwardIcon />}
                            style={{
                              textTransform: "none",
                              marginLeft: "20px",
                              width: "200px",
                              marginTop: "20px",
                            }}
                          >
                            Login
                          </Button>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Paper>
                </Menu>
                <Tab
                  className={classes.tab}
                  component={Link}
                  to="/ContactUs"
                  label="Contact us"
                ></Tab>
              </Tabs>
              <img
                alt="company logo"
                style={{ width: "220px", marginLeft: "180px" }}
                src={logo}
              />
              <Button
                component={Link}
                to="/StartHere"
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowForwardIcon />}
                style={{
                  textTransform: "none",
                  marginLeft: "auto",
                  width: "200px",
                }}
              >
                Start Here
              </Button>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}

export default Header;
